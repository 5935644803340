import React, { Component } from "react";

import "./Mute.css";

const backgroundSoundPath = process.env.PUBLIC_URL + '/Background_Sound.wav';

class Mute extends Component {
    constructor(props) {
        super(props);

        this.state = {
            sound: new Audio(backgroundSoundPath),
            isPlaying: false
        };

        this.toggleSound = this.toggleSound.bind(this);
    }

    toggleSound() {
        if (this.state.isPlaying) {
            this.state.sound.pause();

            this.setState({ isPlaying: false });
        }
        else {
            this.state.sound.currentTime = 0;
            this.state.sound.loop = true;
            this.state.sound.play();

            this.setState({ isPlaying: true });
        }
    }

    render() {
        return (
            <button onClick={() => { this.props.onClick(); this.toggleSound(); }}>
                <svg
                    id={"SoundIcon"}
                    version="1.1"
                    width="95%"
                    height="95%"
                    viewBox="0 0 480 480"
                >
                    <path d="m 88.440807,478.64824 c -36.1451,-7.62964 -62.27923,-34.91632 -67.60684,-70.58842 -4.33407,-29.01969 8.30383,-59.95363 31.7495,-77.71366 14.9827,-11.34932 31.47965,-16.84393 50.691183,-16.88357 15.50359,-0.032 26.63684,2.873 42.8928,11.19201 0.51562,0.26388 0.9375,-53.84835 0.9375,-120.2494 0,-66.40104 0.1809,-120.729162 0.40201,-120.729162 0.39695,0 285.65173,-81.8965 289.93669,-83.24067009 2.11196,-0.66252 2.19764,7.07979009 1.86406,168.46062209 -0.391,189.16587 0.36671,173.58982 -9.41452,193.53005 -3.46575,7.06533 -7.22429,12.0077 -15.25598,20.06116 -10.9689,10.99865 -19.38038,16.37319 -33.43814,21.36539 -10.2782,3.65 -33.09215,4.60597 -44.09412,1.84769 -14.01993,-3.51492 -27.97055,-11.25338 -38.60715,-21.41553 -17.45664,-16.67797 -25.37974,-34.88936 -25.55284,-58.73371 -0.11662,-16.05729 1.97481,-25.34574 8.86568,-39.375 6.22435,-12.67231 22.14795,-28.82525 34.91871,-35.42163 23.2792,-12.0242 50.08452,-12.78657 73.66411,-2.09507 4.569,2.07169 8.51073,3.7667 8.75939,3.7667 0.24865,0 0.4521,-31.78125 0.4521,-70.625 0,-57.98043 -0.29805,-70.625 -1.66476,-70.625 -1.21498,0 -193.26597,54.7612 -208.60604,59.48169 -2.00446,0.61682 -2.17256,8.53922 -2.552,120.27681 -0.45543,134.1148 0.17906,124.83319 -9.85504,144.16499 -9.8092,18.89852 -27.80669,34.1753 -47.78401,40.56045 -11.3028,3.6126 -30.921082,5.05292 -40.702293,2.98826 z" />
                    {this.props.isMuted ? (
                        <g>
                            <path
                                d="M 22.423358,103.47445 33.635037,20.321168 456.87592,364.14598 l -8.40877,93.43066 z"
                                id="slash"
                            />
                        </g>
                    ) : (
                        ""
                    )}
                </svg>
            </button>
        );
    }
}

export default Mute;
