import React from "react";

const Putter = () => {
    return (
        <div id="clubSvgWrapper-Putter" className="putter">
            <svg width="100%" height="100%" viewBox="0 0 229.61097 259.0184">
                <g transform="translate(-1096.8631,-1067.6795)">
                    <path
                        d="m 1182.9967,1106.3684 34.0625,25.1563 q -7.0312,32.1093 -8.9062,32.1093 -0.3907,0 -18.75,15.7813 -11.4063,-0.5469 -26.0938,-1.4844 l -1.0156,28.125 q -3.2031,0.9375 -27.6563,1.9531 -2.9687,0 -2.9687,-16.7968 -5.3906,-69.5313 -5.3906,-75 9.1406,-1.9532 56.7187,-9.8438 z m -21.1719,22.1875 0,32.5781 q 19.2188,-3.4375 19.2188,-3.9843 l 4.4531,-19.2188 q -9.4531,-3.9062 -23.6719,-9.375 z"
                        className="letters"
                    />
                    <path d="m 1316.1134,1077.7447 c -5.5604,0.2365 -11.1208,0.4732 -16.6812,0.7099 -15.9506,35.355 -32.9253,70.3412 -45.802,106.9805 -0.6141,1.3853 0.018,2.9773 0.8518,4.125 3.36,2.5012 9.611,8.912 14.7734,7.1158 14.0078,-4.8736 16.7897,-32.6776 19.4447,-40.0204 2.2669,-6.412 4.818,-12.7182 7.1101,-19.12 7.1071,-19.8492 13.9159,-39.805 20.5935,-59.8031 z m -60.3933,118.9996 c -3.9964,3.395 -5.7628,8.6418 -6.9742,13.5861 -2.5277,10.3164 -1.3394,21.3036 -0.201,31.8639 0.4828,4.4787 1.0292,9.0847 2.9634,13.1851 1.2696,2.5634 2.9952,5.0523 5.4848,6.5555 1.8628,1.0989 4.2232,1.3426 6.2301,0.4877 1.8908,-0.7416 3.8921,-1.8189 4.8281,-3.5933 6.9646,-13.2037 4.4652,-31.548 1.7815,-44.7484 -1.0821,-4.9773 -2.2198,-10.5411 -5.8457,-14.1184 -2.0967,-2.0686 -5.3073,-3.7771 -8.2086,-3.27 -0.026,0 -0.058,0.052 -0.058,0.052 z m 13.7138,67.9075 c -3.7718,-0.1331 -7.5371,0.2523 -11.2792,0.6497 -46.7469,-1.143 -93.4938,-2.2859 -140.2407,-3.4289 m 0,0 -0.036,0.014 c -3.9358,1.4946 -7.2486,4.5498 -8.9075,8.4266 -2.5931,11.032 -4.2593,33.308 4.7318,35.6461 49.3076,12.8222 108.902,12.6071 155.7339,7.1726 4.7,-0.5454 9.4158,-1.2402 13.9453,-2.597 5.9925,-1.795 12.2457,-3.4505 17.3002,-7.2729 0.8662,-0.7121 1.2988,-1.8074 1.7919,-2.7889 1.7318,-3.8608 1.7075,-8.3449 0.3815,-12.3231 -1.8019,-5.657 -5.5408,-10.559 -10.068,-14.3317 -1.686,-1.3561 -3.5193,-2.5095 -5.252,-3.8017 -5.3019,-3.604 -11.7417,-5.2261 -18.1007,-5.3647 m -3.1086,13.1758 c 11.2456,-0.1547 17.7987,13.8975 14.3739,18.4464 -0.6365,0.8339 -1.3086,1.7598 -2.381,2.0326 -11.6217,3.8901 -22.8641,2.6658 -34.3534,3.1185 -32.2597,1.2712 -64.713,2.6928 -96.8477,-1.1396 -4.7913,0.1858 -9.3317,-2.5031 -12.1506,-6.2796 -2.5355,-3.3366 -4.085,-7.7995 -2.8327,-11.8847 0.8096,-2.5077 2.9933,-4.5073 5.4867,-5.373 2.1784,-0.8551 4.5471,-1.0593 6.8524,-0.6864" />
                </g>
            </svg>
        </div>
    );
};

export default Putter;
