import React from "react";

const Close = props => {
    return (
        <button id="closeDialog" onClick={props.onClick}>
            <svg
                id={"Close"}
                version="1.1"
                width="95%"
                height="95%"
                viewBox="0 0 117.73437 101.64063"
            >
                <g transform="translate(-145.71429,-256.43585)">
                    <path d="m 217.97992,256.43585 0.54687,0 q 27.1875,15.9375 29.29688,16.64063 l 0,0.9375 q -7.03125,8.04687 -25.9375,27.89062 37.03125,13.75 41.5625,16.5625 l -22.96875,28.35938 q -23.28125,-19.84375 -29.76563,-24.92188 -15.70312,30.70313 -19.53125,36.17188 l -0.54687,0 q -29.14063,-10.23438 -31.71875,-12.1875 l 0,-0.54688 26.875,-35.15625 q -37.42188,-15.39062 -40.07813,-18.04687 16.48438,-27.96875 18.59375,-29.84375 0.23438,0.78125 36.09375,24.92187 12.73438,-23.4375 17.57813,-30.78125 z" />
                </g>
            </svg>
        </button>
    );
};

export default Close;
