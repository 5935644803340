import React, { Component } from "react";
import Modal from "react-modal";

import { getBag } from "../Slot/SlotHelper";
import DialogSection from "./DialogSection";

import "./Dialog.css";

import Close from "../Button/Close";

class Dialog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            bag: {}
        };

        this.updateBag = this.updateBag.bind(this);
        this.closeModal = this.closeModal.bind(this);
    }

    componentWillMount() {
        this.updateBag();
    }

    updateBag() {
        this.setState({ bag: getBag() });
    }

    closeModal() {
        this.props.closeDialog();
    }

    render() {
        let customStyle = {
            overlay: {
                position: "fixed",
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                backgroundColor: "rgba(0, 0, 0, 0.75)",
                zIndex: "10001"
            },
            content: {
                position: "absolute",
                top: "40px",
                left: "40px",
                right: "40px",
                bottom: "40px",
                border: "5px solid #5d5d5d",
                overflow: "auto",
                WebkitOverflowScrolling: "touch",
                borderRadius: "4px",
                outline: "none",
                padding: "20px"
            }
        };

        return (
            <Modal
                isOpen={this.props.isOpen}
                onAfterOpen={() => {}}
                onRequestClose={() => {}}
                contentLabel="Choose your clubs"
                ariaHideApp={false}
                style={customStyle}
                className={{
                    base: "clubDialog",
                    afterOpen: "clubDialog_after-open",
                    beforeClose: "clubDialog_before-close"
                }}
            >
                <Close onClick={this.closeModal} />
                <div id="clubListWrapper">
                    <DialogSection
                        clubs={Object.entries(this.state.bag)}
                        index={1}
                        updateBag={this.updateBag}
                    />
                    <DialogSection
                        clubs={Object.entries(this.state.bag)}
                        index={2}
                        updateBag={this.updateBag}
                    />
                    <DialogSection
                        clubs={Object.entries(this.state.bag)}
                        index={3}
                        updateBag={this.updateBag}
                    />
                </div>
            </Modal>
        );
    }
}

export default Dialog;
