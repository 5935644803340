import React, { Component } from "react";
import "./WheelItem.css";

class WheelItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            offset: 0
        };
    }

    componentDidMount() {
        let animate = function (handle) {
            handle();
            requestAnimationFrame(() => {
                animate(handle);
            });
        };

        animate(() => {
            let shouldAnimate = this.props.shouldAnimate;
            let currentOffset = Math.floor(this.state.offset % (360 / this.props.total));
            let hasCompletedCurrentRotation = currentOffset === 0;
            if (shouldAnimate || !hasCompletedCurrentRotation) {
                this.setState({
                    offset: this.state.offset + Math.floor(1 * this.props.rate)
                });
            }
        });
    }

    render() {
        let degree =
            (360 /
                this.props.total *
                (this.props["item-index"] - this.props.position) -
                this.state.offset) %
            360;

        let shouldDisplay = (degree > -60) || (degree < -300);

        let zTranslate = 0;

        if (shouldDisplay) {
            zTranslate = Math.round(
                this.props.dimensions.height /
                2 /
                Math.tan(Math.PI / this.props.total)
            );
        }

        let style = {
            color: "black",
            backgroundColor: "white",
            display: shouldDisplay ? "flex" : "none",
            width: this.props.dimensions.width,
            height: this.props.dimensions.height * 1.05,
            transform:
                "rotateX(" + degree + "deg) translateZ(" + zTranslate + "px)",
            WebkitTransform:
                "rotateX(" + degree + "deg) translateZ(" + zTranslate + "px)"
        };

        return (
            <div style={style} className="WheelItem">
                <span>{this.props.children}</span>
            </div>
        );
    }
}

export default WheelItem;
