import React from "react";

const PitchingWedge = () => {
    return (
        <div id="clubSvgWrapper-PitchingWedge" className="wedge">
            <svg width="100%" height="100%" viewBox="0 0 419 419">
                <path
                    className="letters"
                    d="m 190.75945,57.63562 0.54688,1.015625 4.0625,59.375005 0.46875,0 21.32812,-22.343755 q 18.82813,25.390625 19.29688,25.390625 8.125,-39.765625 10.15625,-47.734375 34.53125,6.640625 34.53125,8.125 -20.78125,66.015625 -23.82813,74.687505 -7.34375,0.46875 -32.5,3.04687 -11.17187,-26.71875 -11.17187,-27.42187 -0.9375,0.0781 -11.71875,23.35937 l -0.46875,0 Q 167.94695,152.87 167.94695,151.07312 149.11883,68.026245 149.11883,65.76062 l 0,-1.015625 q 0,-1.5625 41.64062,-7.109375 z m -84.60937,-0.07813 34.0625,25.15625 q -7.03125,32.109375 -8.90625,32.109375 -0.39063,0 -18.75,15.78125 Q 101.15008,130.0575 86.462578,129.12 l -1.015625,28.125 q -3.203125,0.9375 -27.65625,1.95312 -2.96875,0 -2.96875,-16.79687 -5.390625,-69.531255 -5.390625,-75.000005 9.140625,-1.953125 56.718752,-9.84375 z m -21.171877,22.1875 0,32.578125 q 19.218747,-3.4375 19.218747,-3.98437 l 4.45313,-19.218755 q -9.453127,-3.90625 -23.671877,-9.375 z"
                />
                <path d="m 74.667161,236.96023 61.091309,0 0,5.55375 -61.091309,0 z m 0,11.10751 83.923419,0 0,5.55376 -83.923419,0 z m 0,10.49042 99.967599,0 0,6.17084 -99.967599,0 z m 0,10.49045 113.543449,0 0,5.55375 -113.543449,0 z m 0,10.49041 119.097199,0 0,5.55376 -119.097199,0 z m 0,10.49042 120.331369,0 0,6.17084 -120.331369,0 z m 0,11.72461 120.331369,0 0,4.93667 -120.331369,0 z m 0,10.49042 120.948459,0 0,4.93667 -120.948459,0 z m 0,9.87335 120.948459,0 0,6.17084 -120.948459,0 z m 0,10.49042 119.714289,0 0,6.17084 -119.714289,0 z m 0,12.34167 119.714289,0 0,5.55376 -119.714289,0 z m 0,10.49045 119.714289,0 0,4.31959 -119.714289,0 z m 0,10.49042 120.948459,0 0,4.31959 -120.948459,0 z m 0,10.49041 90.711339,0 0,4.93668 -90.711339,0 z M 386.91211,9.8730469 309.1582,170.93164 c 0,0 -3.08518,15.42774 24.6836,15.42774 l 79.60351,-176.4863331 -26.5332,0 z m -85.1582,165.3789031 -19.12891,39.49414 1.18945,0.5625 c 2.21054,4.25108 11.31666,18.62238 31.79102,15.02735 l 0.95898,0.45312 15.42578,-37.64258 c -30.8542,1.23417 -30.23632,-17.89453 -30.23632,-17.89453 z M 92.503906,210.91211 c -0.61634,0.02 -1.214452,0.0634 -1.792968,0.13086 -37.025037,4.31959 -53.685043,19.12944 -62.324219,33.93945 -8.639175,14.81002 -32.706781,104.90445 3.701172,140.69531 36.407953,35.79087 204.254829,-9.87285 209.808589,-20.36328 l 71.58204,-126.5039 c 0,0 -20.36371,9.25747 -34.55664,-16.04297 l -35.17383,78.36914 c 0,0 -112.41472,-91.48592 -151.244144,-90.22461 z m -12.421875,7.95703 c 4.989702,-0.0542 12.017257,0.11828 21.736329,0.8125 25.91752,1.85125 96.88281,53.6875 96.88281,53.6875 l 0,104.90234 -0.61719,0 C 139.46101,394.93274 69.730469,391.23047 69.730469,391.23047 L 69.113281,219.68164 c 0,0 2.65258,-0.72211 10.96875,-0.8125 z m -5.41487,8.21775 38.876289,0 0,4.93667 -38.876289,0 z" />
            </svg>
        </div>
    );
};

export default PitchingWedge;
