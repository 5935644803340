import React from "react";
import { connect } from "react-redux";
import { restart } from "../../State/Actions";

import "./Restart.css";

const Restart = props => {
    return (
        <div id="Restart" className={props.isFinished ? "show" : "hide"}>
            <div
                className="thing"
                onClick={props.onClick}
            >
                <svg
                    width="100%"
                    height="100%"
                    viewBox="0 0 326.76159 332.85946"
                >
                    <g transform="translate(-193.01339,-176.17193)">
                        <path d="m 404.04563,274.99684 -84.49624,13.82666 36.87109,46.08885 -59.91551,33.79849 -52.23404,-36.87108 10.75407,-56.84292 18.43554,-47.62515 89.10512,-12.29036 82.95994,27.65331 24.58073,101.39549 -27.65332,93.714 -110.61325,27.65331 -138.26657,-50.69774 4.60888,58.37921 176.67395,35.3348 122.90362,-44.55256 21.50813,-133.65769 -19.97184,-110.61325 -35.33479,-39.94367 -149.02063,-3.07259 -106.00437,43.01626 -7.68148,138.26657 72.20588,52.23404 59.91551,3.07258 c 30.52099,-12.97271 38.13435,-31.71614 52.23404,-59.91551 l 38.40738,18.43555 z" />
                    </g>
                </svg>
            </div>
        </div>
    );
};

const mapStateToProps = state => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClick: () => {
            dispatch(restart());
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Restart);
