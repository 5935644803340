import React from "react";

import { toggleClub } from "../Slot/SlotHelper";

const DialogSection = props => {
    let clubs = props.clubs;
    let buttonMaker = function(club) {
        return (
            <button
                className={
                    club[1]["visible"] ? "clubButton selected" : "clubButton"
                }
                key={club[0]}
                onClick={() => {
                    toggleClub(club[0]);
                    props.updateBag();
                }}
            >
                {club[0]}
            </button>
        );
    };
    return (
        <div className="dialogSection">
            <h2>{"Slot " + props.index}</h2>
            {clubs.filter(club => club[1]["slot"] === props.index).map(club => {
                return buttonMaker(club);
            })}
        </div>
    );
};

export default DialogSection;
