export const stopWheel = index => {
    return {
        type: "STOP_WHEEL",
        index
    };
};

export const startWheel = index => {
    return {
        type: "START_WHEEL",
        index
    };
};

export const startWheelSound = () => {
    return {
        type: "START_WHEEL_SOUND"
    };
};

export const toggleWheel = index => {
    return {
        type: "TOGGLE_WHEEL",
        index
    };
};

export const restart = () => {
    return {
        type: "RESTART"
    };
};

export const attemptToRestart = index => {
    return {
        type: "ATTEMPT_TO_RESTART",
        index
    };
};

export const toggleMute = () => {
    return {
        type: "TOGGLE_MUTE"
    };
};

export const isMobile = () => {
    return {
        type: "IS_MOBILE"
    };
};

export const toggleDialog = () => {
    return {
        type: "TOGGLE_DIALOG"
    };
};
