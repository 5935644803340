import React, { Component } from "react";
import "./App.css";

import Header from "../Components/Header/Header";
import Machine from "../Components/Machine/Machine";
import WashIn from "../Components/WashIn/WashIn";
import Restart from "../Components/Restart/Restart";
import Footer from "../Components/Footer/Footer";
import Dialog from "../Components/Dialog/Dialog";

import { getClubsForSlots } from "../Components/Slot/SlotHelper";

import { connect } from "react-redux";
import {
    startWheel,
    startWheelSound,
    toggleMute,
    isMobile,
    toggleDialog
} from "../State/Actions";

class App extends Component {
    constructor(props) {
        super(props);

        this.state = {
            slotClubs: []
        };

        this.startWheel = this.startWheel.bind(this);
        this.setClubsFromStorage = this.setClubsFromStorage.bind(this);
    }

    setClubsFromStorage() {
        let slotClubs = getClubsForSlots();
        this.setState({ slotClubs });
    }

    startWheel() {
        setTimeout(() => {
            this.props.startFirst();
        }, 1750);
        setTimeout(() => {
            this.props.startSecond();
        }, 2000);
        setTimeout(() => {
            this.props.startThird();
        }, 2250);
    }

    componentWillMount() {
        this.setClubsFromStorage();
    }

    componentDidMount() {
        if (/iPhone|iPad|iPod|Android/i.test(navigator.userAgent)) {
            this.props.isMobile();
        } else {
            console.log("Not Hotdog");
        }
        this.startWheel();
    }

    render() {
        return (
            <div className="Background">
                <div id="HorizontalLeft" />
                <div id="HorizontalCenter">
                    <div
                        id="App"
                        className={this.props.state.app.isFinished ? "blur" : ""}
                    >
                        <WashIn />
                        <div id="VerticalTop">
                            <Header />
                        </div>
                        <Machine
                            slot1={this.state.slotClubs["1"]}
                            slot2={this.state.slotClubs["2"]}
                            slot3={this.state.slotClubs["3"]}
                        />
                        <div id="VerticalBottom">
                            <Footer
                                toggleSound={() => {
                                    this.props.toggleMute();
                                }}
                                isMuted={this.props.state.app.isMuted}
                                isMobile={this.props.state.app.isMobile}
                                toggleDialog={() => {
                                    this.props.toggleDialog();
                                }}
                            />
                        </div>
                    </div>
                    <Restart isFinished={this.props.state.app.isFinished} />
                    <Dialog
                        isOpen={this.props.state.app.isDialogOpen}
                        closeDialog={() => {
                            this.setClubsFromStorage();
                            this.props.toggleDialog();
                        }}
                    />
                </div>
                <div id="HorizontalRight" />
            </div>
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        startFirst: () => {
            dispatch(startWheel(0));
        },
        startSecond: () => {
            dispatch(startWheel(1));
        },
        startThird: () => {
            dispatch(startWheel(2));
        },
        startWheelSound: () => {
            dispatch(startWheelSound());
        },
        toggleMute: () => {
            dispatch(toggleMute());
        },
        isMobile: () => {
            dispatch(isMobile());
        },
        toggleDialog: () => {
            dispatch(toggleDialog());
        },
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
