const initialState = {
    wheels: [
        {
            spinning: false,
            rate: 12,
            enabled: true,
            pushed: false
        },
        {
            spinning: false,
            rate: 15,
            enabled: false,
            pushed: false
        },
        {
            spinning: false,
            rate: 18,
            enabled: false,
            pushed: false
        }
    ],
    app: {
        isFinished: false,
        isMuted: true,
        isMobile: false,
        sounds: {
            wheel: {
                play: false
            },
            background: {
                play: false
            }
        },
        isDialogOpen: false
    }
};

const WheelState = (state = initialState, action) => {
    let newState = {};
    let currentWheel = {};
    switch (action.type) {
        case "TOGGLE_WHEEL":
            newState = Object.assign({}, state);

            currentWheel = Object.assign({}, state.wheels[action.index]);
            currentWheel.spinning = !currentWheel.spinning;
            currentWheel.enabled = !currentWheel.enabled;
            currentWheel.pushed = !currentWheel.pushed;

            newState.wheels[action.index] = currentWheel;

            if (action.index < initialState.wheels.length - 1) {
                let nextWheel = Object.assign(
                    {},
                    state.wheels[action.index + 1]
                );

                nextWheel.enabled = !nextWheel.enabled;
                newState.wheels[action.index + 1] = nextWheel;
            } else {
                newState.app.sounds.wheel.play = false;
                newState.app.sounds.background.play = true;
            }

            return newState;
        case "START_WHEEL":
            newState = Object.assign({}, state);

            currentWheel = Object.assign({}, state.wheels[action.index]);
            currentWheel.spinning = true;

            newState.wheels[action.index] = currentWheel;

            return newState;
        case "ATTEMPT_TO_RESTART":
            newState = Object.assign({}, state);
            let canRestart = action.index === initialState.wheels.length - 1;
            if (canRestart) {
                newState.app.isFinished = true;
            }
            return newState;
        case "START_WHEEL_SOUND":
            newState = Object.assign({}, state);
            newState.app.sounds.background.play = false;
            newState.app.sounds.wheel.play = true;
            return newState;
        case "RESTART":
            newState = Object.assign({}, initialState);
            newState.wheels.map((wheel, index) => {
                wheel.spinning = true;
                wheel.pushed = false;
                if (index === 0) {
                    wheel.enabled = true;
                }
                return wheel;
            });
            newState.app.isFinished = false;
            newState.app.sounds.wheel.play = true;
            newState.app.sounds.background.play = false;
            return newState;
        case "TOGGLE_MUTE":
            newState = Object.assign({}, state);
            newState.app.isMuted = !newState.app.isMuted;
            return newState;
        case "IS_MOBILE":
            newState = Object.assign({}, state);
            newState.app.isMobile = true;
            return newState;
        case "TOGGLE_DIALOG":
            newState = Object.assign({}, state);
            newState.app.isDialogOpen = !newState.app.isDialogOpen;
            return newState;
        default:
            return state;
    }
};

export default WheelState;
