import React from "react";
import { connect } from "react-redux";
import { toggleWheel, attemptToRestart } from "../../State/Actions";

import Star from "./Star";

import "./Button.css";

const pushButtonSoundPath = process.env.PUBLIC_URL + '/Button_Sound.wav';

const position = ["left", "center", "right"];

const Button = props => {
    return (
        <button
            disabled={!props.state.wheels[props.index]["enabled"]}
            onClick={() => {
                if (!props.state.app.isMuted) {
                    let sound = new Audio(pushButtonSoundPath);
                    sound.play();
                }

                props.onClick();
            }}
            className={
                "button " +
                (props.state.wheels[props.index]["enabled"]
                    ? ""
                    : "disabled ") +
                (props.state.wheels[props.index]["pushed"] ? "pushed " : "") +
                position[props.index]
            }
        >
            <Star />
        </button>
    );
};

const mapStateToProps = state => {
    return {
        state
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClick: () => {
            dispatch(toggleWheel(ownProps.index));
            let myAttemptToRestart = attemptToRestart;
            setTimeout(() => {
                dispatch(myAttemptToRestart(ownProps.index));
            }, 4000);
        }
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Button);
