import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore } from "redux";
import registerServiceWorker from "./registerServiceWorker";

import "./index.css";

import WheelState from "./State/Reducers";
import App from "./App/App";

let wheelState = createStore(
    WheelState,
    window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

ReactDOM.render(
    <Provider store={wheelState}>
        <App />
    </Provider>,
    document.getElementById("root")
);
registerServiceWorker();
