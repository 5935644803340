import React, { Component } from "react";
import "./Slot.css";

import Wheel from "../Wheel/Wheel";
import Button from "../Button/Button";

class Slot extends Component {
    render() {
        return (
            <div className="slot">
                <div className="wheel-top" />
                <div className="wheel-wrapper">
                    <Wheel
                        position={this.props.position}
                        items={this.props.items}
                        index={this.props.index}
                    />
                </div>
                <div className="slot-separator"></div>
                <div className="button-wrapper">
                    <Button index={this.props.index} />
                </div>
            </div>
        );
    }
}

export default Slot;
