import React from "react";

const Star = () => {
    return (
        <div id="clubSvgWrapper-Star" className="Star">
            <svg width="100%" height="100%" viewBox="0 0 210.51512 212.70665">
                <path
                    d="m 41.168453,203.05989 c -2.73293,-3.01985 -2.81186,-10.70298 -0.27095,-26.3702 1.624,-10.01357 6.88556,-34.20275 8.76031,-40.27417 0.39515,-1.2797 -3.12587,-5.16136 -10.34414,-11.40361 C 24.373653,112.092 8.1829234,96.008167 5.2117034,91.135117 c -4.98053,-8.16848 -2.55262,-12.06328 9.4087596,-15.09331 6.92319,-1.75376 28.55962,-4.65819 48.125,-6.4602 l 8.125,-0.74833 4.22489,-10.23883 C 87.407423,28.756653 99.632973,7.7306065 104.67001,7.7306065 c 5.77337,0 16.0991,16.9282885 28.21105,46.2500005 l 5.93789,14.375 6.02576,0.87207 c 3.31416,0.47963 14.30409,1.79169 24.42205,2.91567 38.99352,4.33171 44.5591,8.10001 33.37469,22.59708 -4.26162,5.523843 -23.10928,23.305673 -37.94318,35.797453 l -3.67217,3.0924 2.7655,11.73767 c 4.88306,20.7253 7.75181,37.49592 8.04402,47.02522 0.33738,11.00155 -1.55242,13.53803 -9.09016,12.20075 -5.54909,-0.98446 -25.10841,-10.75967 -42.51956,-21.2501 -7.4061,-4.46226 -13.89984,-8.11321 -14.43053,-8.11321 -0.53069,0 -8.112627,4.21042 -16.848777,9.3565 -19.10462,11.25366 -35.40562,19.21874 -41.28489,20.17281 -3.28093,0.53241 -4.84303,0.12338 -6.49325,-1.70004 z"
                    id="background"
                />
                <path
                    d="m 61.495463,192.36895 c 5.15625,-2.79673 16.95065,-9.57822 26.20977,-15.06998 9.25913,-5.49176 17.415377,-9.96704 18.124997,-9.94505 0.70963,0.022 10.00898,5.26695 20.66523,11.6555 10.65625,6.38854 23.29377,13.49419 28.0834,15.79031 14.29774,6.85433 14.40289,5.88628 4.29803,-39.56912 -2.67458,-12.03125 -4.86705,-22.50404 -4.87216,-23.27286 -0.005,-0.76883 4.91261,-5.58418 10.92823,-10.70079 14.05518,-11.95469 33.84603,-30.786213 35.39371,-33.678093 0.93654,-1.74995 0.59825,-2.56564 -1.53825,-3.70906 -3.35767,-1.79698 -30.79186,-5.73849 -47.13821,-6.77243 -18.27391,-1.15585 -16.51653,0.0917 -23.2883,-16.53321 -8.52412,-20.927081 -18.56915,-41.916176 -21.02134,-43.924116 -1.77577,-1.454074 -2.41481,-1.454074 -4.19262,0 -2.32909,1.904967 -13.722037,25.277557 -21.618427,44.350126 -2.81573,6.80098 -6.05155,13.06855 -7.1907,13.92793 -1.13915,0.85937 -4.6363,1.5625 -7.77145,1.5625 -7.97066,0 -47.21076,4.65905 -52.88441,6.27907 -2.57812,0.73614 -4.6874996,1.90817 -4.6874996,2.6045 0,2.1386 12.1972996,14.31244 30.9081096,30.848673 11.80497,10.43297 17.65878,16.42981 17.28649,17.70883 -3.85135,13.23184 -11.9446,53.40389 -11.9446,59.28881 0,7.23751 1.49787,7.15993 16.25,-0.84154 z"
                    id="foreground"
                />
                <path
                    id="eyes"
                    d="m 83.752213,122.41811 c -1.01259,-1.8011 -1.57785,-7.79585 -1.5719,-16.67024 0.007,-11.067623 0.44857,-14.528153 2.19104,-17.187503 2.65922,-4.05849 5.09916,-4.23377 7.67823,-0.55162 2.34857,3.35303 2.75647,27.023533 0.57359,33.285333 -1.68876,4.84439 -6.44082,5.44652 -8.87096,1.12403 z m 35.198717,0.2293 c -3.36503,-6.28764 -3.01888,-30.708993 0.50355,-35.526213 2.29528,-3.13898 6.52887,-1.40809 8.18651,3.34701 1.87963,5.39194 1.69896,27.130943 -0.25774,31.012403 -2.083,4.13199 -6.51704,4.74554 -8.43231,1.1668 z"
                />
                <path
                    id="pupils"
                    d="m 91.495463,94.605607 c 0,-4.7819 -2.52234,-7.60266 -4.77974,-5.34526 -3.56241,3.56241 -0.57891,13.861433 3.125,10.787463 0.9101,-0.755333 1.65474,-3.204313 1.65474,-5.442203 z m 33.124997,-0.10416 c 0,-4.46897 -0.48316,-6.14584 -1.77084,-6.14584 -2.61159,0 -4.17387,5.87134 -2.63752,9.91225 2.01566,5.301593 4.40836,3.257303 4.40836,-3.76641 z"
                />
            </svg>
        </div>
    );
};

export default Star;
