export default {
    "Driver": "D",
    "ThreeWood": "3W",
    "ThreeHybrid": "3H",
    "ThreeIron": "3I",
    "FourHybrid": "4H",
    "FourIron": "4I",
    "FiveHybrid": "5H",
    "FiveIron": "5I",
    "SixHybrid": "6H",
    "SixIron": "6I",
    "SevenIron": "7I",
    "EightIron": "8I",
    "NineIron": "9I",
    "Putter": "P",
    "PitchingWedge": "PW",
    "SandWedge": "SW",
    "Plank": "Wood"
};