import React from "react";

import Driver from "../Clubs/Driver";
import ThreeWood from "../Clubs/ThreeWood";
import ThreeHybrid from "../Clubs/ThreeHybrid";
import ThreeIron from "../Clubs/ThreeIron";
import FourHybrid from "../Clubs/FourHybrid";
import FourIron from "../Clubs/FourIron";
import FiveHybrid from "../Clubs/FiveHybrid";
import FiveIron from "../Clubs/FiveIron";
import SixHybrid from "../Clubs/SixHybrid";
import SixIron from "../Clubs/SixIron";
import SevenIron from "../Clubs/SevenIron";
import EightIron from "../Clubs/EightIron";
import NineIron from "../Clubs/NineIron";
import Putter from "../Clubs/Putter";
import PitchingWedge from "../Clubs/PitchingWedge";
import SandWedge from "../Clubs/SandWedge";
import Plank from "../Clubs/Plank";
import Random from "../Clubs/Random/Random";

import defaultBag from "../../State/Bag";
import Clerbs from "../../State/Clerbs";

import store from "store";

export function getClubsForSlots() {
    let bag = getBag();

    let slots = Object.entries(bag)
        .map(club => {
            return club[1]["slot"];
        })
        .filter(onlyUnique);

    let uiItems = {};

    slots.forEach(slot => {
        uiItems[slot] = randomizeClubs(
            normalizeClubs(
                Object.entries(bag)
                    .filter(club => {
                        return club[1].visible && club[1].slot === slot;
                    })
                    .map(club => {
                        return clubUiMapper(club[0]);
                    })
            )
        );
    });

    return uiItems;
}

function onlyUnique(value, index, self) {
    return self.indexOf(value) === index;
}

function clubUiMapper(club) {
    switch (club) {
        case Clerbs.Driver:
            return <Driver />;
        case Clerbs.ThreeWood:
            return <ThreeWood />;
        case Clerbs.ThreeHybrid:
            return <ThreeHybrid />;
        case Clerbs.ThreeIron:
            return <ThreeIron />;
        case Clerbs.FourHybrid:
            return <FourHybrid />;
        case Clerbs.FourIron:
            return <FourIron />;
        case Clerbs.FiveHybrid:
            return <FiveHybrid />;
        case Clerbs.FiveIron:
            return <FiveIron />;
        case Clerbs.SixHybrid:
            return <SixHybrid />;
        case Clerbs.SixIron:
            return <SixIron />;
        case Clerbs.SevenIron:
            return <SevenIron />;
        case Clerbs.EightIron:
            return <EightIron />;
        case Clerbs.NineIron:
            return <NineIron />;
        case Clerbs.Putter:
            return <Putter />;
        case Clerbs.PitchingWedge:
            return <PitchingWedge />;
        case Clerbs.SandWedge:
            return <SandWedge />;
        case Clerbs.Plank:
            return <Plank />;
        default:
            return <Random />;
    }
}

function normalizeClubs(clubs) {
    let amount = 0;

    if (clubs.length < 6) {
        amount = 6 - clubs.length;
    } else if (360 % clubs.length !== 0) {
        let findNextFactor = function(test) {
            if (360 % test !== 0) {
                return findNextFactor(test + 1);
            }
            return test;
        };
        amount = findNextFactor(clubs.length) - clubs.length;
    }

    if (amount > 0) {
        for (let i = 0; i < amount; i++) {
            clubs.push(<Random />);
        }
    }
    return clubs;
}

function randomizeClubs(array) {
    var currentIndex = array.length,
        temporaryValue,
        randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
        // Pick a remaining element...
        randomIndex = Math.floor(Math.random() * currentIndex);
        currentIndex -= 1;

        // And swap it with the current element.
        temporaryValue = array[currentIndex];
        array[currentIndex] = array[randomIndex];
        array[randomIndex] = temporaryValue;
    }

    return array;
}

export function getBag() {
    let myBag = store.get("bag");
    if (myBag) {
        return myBag;
    } else {
        setBag(defaultBag);
        return defaultBag;
    }
}

function setBag(bag) {
    store.set("bag", bag);
}

export function toggleClub(club) {
    let bag = getBag();
    bag[club].visible = !bag[club].visible;
    setBag(bag);
}