import React, { Component } from "react";
import { connect } from "react-redux";
import Measure from "react-measure";

import "./Wheel.css";

import WheelItem from "../WheelItem/WheelItem";

class Wheel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            dimensions: {
                width: -1,
                height: -1
            }
        };
    }

    render() {
        let total = this.props.items.length;
        return (
            <Measure
                bounds
                onResize={contentRect => {
                    this.setState({ dimensions: contentRect.bounds });
                }}
            >
                {({ measureRef }) => (
                    <section className="container">
                        <div ref={measureRef} id="wheel">
                            {this.props.items.map((item, itemIndex) => {
                                return (
                                    <WheelItem
                                        key={this.props.index + "+" + itemIndex}
                                        position={this.props.position % total}
                                        item-index={itemIndex}
                                        total={total}
                                        dimensions={this.state.dimensions}
                                        rate={
                                            this.props.state.wheels[
                                                this.props.index
                                            ]["rate"]
                                        }
                                        shouldAnimate={
                                            this.props.state.wheels[
                                                this.props.index
                                            ]["spinning"]
                                        }
                                    >
                                        {item}
                                    </WheelItem>
                                );
                            })}
                        </div>
                    </section>
                )}
            </Measure>
        );
    }
}

const mapStateToProps = state => {
    return {
        state
    };
};

const mapDispatchToProps = () => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(Wheel);
