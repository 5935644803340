import React, { Component } from "react";
import "./Machine.css";

import "../Clubs/Clubs.css";
import Slot from "../Slot/Slot";

class Machine extends Component {
    constructor(props) {
        super(props);

        this.state = {
            position: 0
        };
    }

    render() {
        return (
            <div id="Machine">
                <Slot
                    position={this.state.position}
                    items={this.props.slot1}
                    index={0}
                />
                <Slot
                    className="middleSlot"
                    position={this.state.position + 1}
                    items={this.props.slot2}
                    index={1}
                />
                <Slot
                    position={this.state.position + 2}
                    items={this.props.slot3}
                    index={2}
                />
            </div>
        );
    }
}

export default Machine;
