import Clerbs from "./Clerbs";

let bag = {};
bag[Clerbs.Driver] = { slot: 1, visible: true };
bag[Clerbs.ThreeWood] = { slot: 1, visible: true };
bag[Clerbs.ThreeHybrid] = { slot: 1, visible: false };
bag[Clerbs.ThreeIron] = { slot: 1, visible: true };
bag[Clerbs.FourHybrid] = { slot: 1, visible: true };
bag[Clerbs.FourIron] = { slot: 1, visible: true };
bag[Clerbs.Plank] = { slot: 1, visible: false };
bag[Clerbs.FiveHybrid] = { slot: 2, visible: true };
bag[Clerbs.FiveIron] = { slot: 2, visible: true };
bag[Clerbs.SixHybrid] = { slot: 2, visible: true };
bag[Clerbs.SixIron] = { slot: 2, visible: true };
bag[Clerbs.SevenIron] = { slot: 2, visible: true };
bag[Clerbs.EightIron] = { slot: 3, visible: true };
bag[Clerbs.NineIron] = { slot: 3, visible: true };
bag[Clerbs.Putter] = { slot: 3, visible: true };
bag[Clerbs.PitchingWedge] = { slot: 3, visible: true };
bag[Clerbs.SandWedge] = { slot: 3, visible: true };

export default bag;
