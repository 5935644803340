import React, { Component } from "react";

import Star from "./Star";
import Beer from "./Beer";
import Glove from "./Glove";

class Random extends Component {
    shouldComponentUpdate(nextProps) {
        return false;
    }

    render() {
        switch (Math.floor(Math.random() * 3)) {
            case 0:
                return <Star />;
            case 1:
                return <Beer />;
            default:
                return <Glove />;
        }
    }
}

export default Random;
