import React from "react";

import Bag from "../Button/Bag";
import Mute from "../Button/Mute";

import "./Footer.css";

const Footer = props => {
    return (
        <div id="Footer">
            <div className="FooterItem">
                <Bag onClick={props.toggleDialog}/>
            </div>
            <div
                className="FooterItem"
            >
                <Mute isMuted={props.isMuted} isMobile={props.isMobile} onClick={props.toggleSound}/>
            </div>
        </div>
    );
};

export default Footer;
